<template>
    <div class="fwt-bg-img relative bg-cover bg-no-repeat bg-center w-full pb-[139px] pt-[213px]">
        <img src="assets/images/shap.png" class="absolute left-0 top-0 z-0" alt="">

        <div class="relative">
            <div class="container mx-auto max-w-[1372px]">
                <div class="lg:pt-80 sm:pt-40 pt-28 pt-5">
                    <p class="text-base text-white uppercase">new open yatches</p>
                    <h1 class="uppercase text-white py-2 sm:text-[40px] font-medium leading-tight font-['Cormorant']">
                        book your watertaxi now</h1>
                    <!-- <p class="text-base text-white max-w-[570px]">Some text here...</p> -->

                    <div class="fwt-project-btn flex flex-wrap align-middle gap-5 mt-10">
                        <NuxtLink to="/book-tickets"
                            class="fwt-project-btn-child text-lg font-semibold text-white py-5 max-w-[217px] w-full bg-[#114c8e] text-center uppercase border border-white duration-500 hover:bg-[#f69532] bg-opacity-100">Book
                            tickets</NuxtLink>
                        <NuxtLink to="/book-parcels"
                            class="fwt-project-btn-child text-lg font-semibold text-white py-5 max-w-[217px] w-full bg-[#114c8e] text-center uppercase border border-white duration-500 hover:bg-[#f69532] bg-opacity-100">book
                            parcels</NuxtLink>
                        <NuxtLink to="/book-charter"
                            class="fwt-project-btn-child text-lg font-semibold text-white py-5 max-w-[217px] w-full bg-[#114c8e] text-center uppercase border border-white duration-500 hover:bg-[#f69532] bg-opacity-100">book
                            charters</NuxtLink>
                        <NuxtLink to="/buy-pass"
                            class="fwt-project-btn-child buy-pass-popup-btn text-lg font-semibold text-white py-5 max-w-[217px] w-full bg-[#114c8e] text-center uppercase border border-white duration-500 hover:bg-[#f69532] bg-opacity-100">Buy
                            pass</NuxtLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- about-section  ================================================-->
    <!-- <HomeAbout /> -->

    <!-- offer section  =====================================================-->
    <!-- <HomeOffer /> -->

    <!-- explore section =================================================-->
    <!-- <HomeExplore /> -->

    <!-- fwt-latest-img section ===========================================-->
    <!-- <HomeLatestImage /> -->

    <!-- slider-section =================================================-->
    <HomeSlider />
    <HomeExplore :topDests="topDestList" v-if="topDestList.length > 0"/>

</template>

<script setup lang="ts">
// import HomeExplore from '~/components/HomeExplore.vue';
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// import HomeLatestImage from '~/components/HomeLatestImage.vue';
// import HomeSlider from '~/components/HomeSlider.vue';

const route = useRoute();

definePageMeta({
    layout: 'default'
});
const { topDestList, getTopDestinations } = useListing();
onMounted(async () => {
  await getTopDestinations();
})
useHead({
  title: "Home - MyBoatRide",
  meta: [
    {
      name: "description",
      content:
        "",
    },
    {
      property: "og:title",
      content: "Home - MyBoatRide",
    },
    {
      property: "og:description",
      content:  "",
    },
    {
      property: "og:type",
      content: "website",
    },
  ],
});
</script>