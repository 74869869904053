<script setup lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const modules = ref([Autoplay, Pagination, Navigation]);
const testimonialImgURL: string = import.meta.env.VITE_APP_TESTIMONIAL_PATH;
const { testimonialList, getTestimonialList, showErrorImage } = useListing();
onMounted(async () => {
  await getTestimonialList();
});

function gotoTestLink(testimonial: any) {
  if (testimonial.text) {
    window.open(testimonial.text);
  }
} 
</script>

<template>
  <div class="fwt-slider-section md:mt-20 mt-16 md:mb-32 mb-16" v-if="testimonialList.length > 0">
    <div class="container max-w-[1172px] mx-auto relative">
      <div class="fwt-slider-content relative">
        <h6 class="fwt-top-heading-text flex items-center text-[#737373] sm:mb-4 mb-3 uppercase">
          <span class="w-8 h-5 bg-[#f69532] inline-block mr-4"></span>TESTIMONIALS
        </h6>

        <h2
          class="fwt-text-heading sm:mb-7 mb-3 text-[40px] leading-tight font-semibold text-[#114C8E] uppercase font-[Cormorant]">
          PEOPLE ABOUT US
        </h2>

        <div class="fwt-bg-line max-w-[270px] w-full h-[1px] bg-[#D9D9D9]"></div>

        <div class="fwt-swiper-icon absolute right-0 top-0">
          <img src="assets/images/swiper-icon.png" alt="" />
        </div>
        <!-- {
            delay: 2500,
            disableOnInteraction: false,
          } -->
        <swiper class="mySwiper sm:mt-14 mt-10" 
          :slides-per-view="2"
          :space-between="50"
          :autoplay="true"
          :loop="true"
          :breakpoints="{
            0: {
            slidesPerView: 1,
            },
            500: {
            slidesPerView: 2,
            },
            768: {
            slidesPerView: 3,
            },
            }">
          <swiper-slide v-for="testimonial in testimonialList" :key="testimonial.id">
            <div class="fwt-swiper-content flex sm:items-center items-start" @click="gotoTestLink(testimonial)"
              :class="[testimonial.text ? 'cursor-pointer' : '']">
              <!-- <a
                  :title="testimonial.text"
                  :href="`${testimonial.text}`"
                  target="_blank"
                  class="absolute bottom-0"
                > -->
              <div class="fwt-swiper-img">
                <!-- <img
                    class="w-[172px] max-w-[172px]"
                    src="assets/images/slider-1.png"
                    alt=""
                  /> -->
                  <!-- @error="showErrorImage($event, '/images/default-image.jpg')" -->
                <img :src="`${testimonialImgURL}${testimonial?.file}`" class="w-[172px] max-w-[172px]" alt="ticket-icon"
                   />
              </div>
              <div class="fwt-slider-text ml-7 sm:min-h-[165px] min-h-[100px] max-h-[165px] relative">
                <p class="fwt-simple-text max-w-[420px]"></p>
                <h6 class="text-[#f69532] font-bold sm:mt-4 mt-0">
                  {{ testimonial.name }}
                </h6>
                <p class="line-clamp-3 fwt-simple-text sm:my-2 my-1" v-html="testimonial.description"></p>

                <!-- <a
                    :title="testimonial.text"
                    :href="`${testimonial.text}`"
                    target="_blank"
                    class="absolute bottom-0"
                  > -->
                <ClientOnly>
                  <font-awesome-icon icon="fa-brands fa-instagram"
                    class="bottom-0 text-2xl m-0 font-bold text-center text-[#1d456b] cursor-pointer toolTip" />
                </ClientOnly>
                <!-- </a> -->
              </div>
              <!-- </a> -->
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>
<style scoped>
.toolTip:hover {
  color: rgb(243, 60, 167);
}
</style>
