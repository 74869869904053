<template>
  <!-- <div class="fwt-explore-section max-w-full">
        <div class="fwt-explore-bg relative bg-center bg-no-repeat bg-cover w-full h-[479px]">
            <a
                class="fwt-explore-btn absolute inset-x-1/2 -translate-x-2/4 bottom-[70px] cursor-pointer text-white uppercase border text-lg max-w-[217px] w-full text-center py-[19px]">explore</a>
        </div>
    </div> -->
  <!-- destination section -->
  <div
    class="fwt-destination-slider relative w-full bg-center bg-cover bg-no-repeat py-20"
  >
    <div class="container mx-auto">
      <div class="destination-slider-cont relative z-10">
        <h2
          class="fwt-text-heading text-center sm:text-[40px] text-3xl leading-tight font-semibold text-[#fff] uppercase font-[Cormorant]"
        >
          Top Destination
        </h2>
        <div
          class="fwt-bg-line mx-auto mt-[10px] mb-[43px] fwt-bg-line max-w-[330px] w-full h-[1px] bg-[#EAC485]"
        ></div>

        <swiper
          class="cursor-pointer destiSwiper relative"
          :slides-per-view="4"
          :space-between="50"
          :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
          }"
          :loop="true"
          :breakpoints="{
            0: {
            slidesPerView: 1,
            },
            500: {
            slidesPerView: 2,
            },
            768: {
            slidesPerView: 3,
            },
            }"
          :modules="modules"
        >
          <SwiperSlide class="text-center inline-block" v-for="(dest, index) in props.topDests" :key="index">
            <h3 class="md:text-2xl py-5 text-xl font-bold text-[#f69634] uppercase">
              {{ dest.source_port_name + ' - ' + dest.destination_port_name }}
            </h3>
            <div
              class="desti-line absolute bottom-0 w-full h-px bg-white inline-block my-3"
            ></div>
          </SwiperSlide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

const modules = ref([Autoplay, Pagination, Navigation]);

const props = defineProps({
  topDests: {
    type: Array<{
      source_port_id: "",
      source_port_name: "",
      destination_port_id: "",
      destination_port_name: ""
    }>,
  }
})
</script>

<style scoped>
.destiSwiper{
  padding-bottom: 2rem !important;
}
</style>
